<template>
  <div class="result-detail">
    <div class="left">
      <div class="molecule-img">
        <img id="smilesimg" :src="imgUrl" />
      </div>
      <div class="basic-infos card">
        <h3>Basic Information</h3>
        <div class="basic-item" v-for="item in basicInfo" :key="item.key">
          <div class="basic-key">{{ item.key + " :" }}</div>
          <div class="basic-value">{{ item.value }}</div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="performance">
        <h3>Molecule Information</h3>
        <div class="performance-item" v-for="item in modelPerformance" :key="item.name">
          <div class="performance-key">{{ item.key }}</div>
          <div class="performance-value">{{ item.value }}</div>
        </div>
      </div>
      <div class="hyper-parameters card">
        <h3>Hyper Parameters</h3>
        <div class="hyper-items">
          <div class="hyper-item" v-for="item in hyperParameters" :key="item.key">
            <div class="hyper-key">{{ item.key + " :" }}</div>
            <div class="hyper-value">{{ item.value }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["data"],
  data() {
    return {
      params: {},
      modelPerformance: [
        {
          key: "TN",
          value: "0.9",
        },
        { key: "TP", value: "0.81" },
        { key: "FN", value: "0.91" },
        {
          key: "FP",
          value: "0.8",
        },
        {
          key: "AUC",
          value: "0.78",
        },
        { key: "ACC", value: "0.9" },
        { key: "SE", value: "0.85" },
        {
          key: "SP",
          value: "0.9",
        },
      ],
      basicInfo: [
        {
          key: "Target Name",
          value: "HT-29",
        },
        {
          key: "Training Dataset Size",
          value: 1800,
        },
        {
          key: "Active Sample Amount",
          value: 1000,
        },
        {
          key: "Inactive Sample Amount",
          value: 800,
        },
      ],
      imgUrl: "",
      hyperParameters: [
        {
          key: "FPN dropout",
          value: 0.91,
        },
        {
          key: "GAN dropout",
          value: 0.9,
        },
        {
          key: "Nhidden of FPN",
          value: 0.676,
        },
        {
          key: "GAT scale",
          value: 0.829,
        },
        {
          key: "Nheads",
          value: 0.91,
        },
        {
          key: "Nhidden",
          value: 0.91,
        },
      ],
    };
  },
  mounted() {
    // this.params = JSON.parse(this.$route.query.obj);
    // this.moleculename = Object.keys(this.params.smilesinfo)[0];
    // activityApi
    //   .getDetailModelInfo({
    //     mode: this.params.mode,
    //     model: this.params.targetname,
    //   })
    //   .then((res) => {
    //     this.getParam(res.data);
    //   });
    this.dealwithParam();
  },
  updated() {
    this.dealwithParam();
  },
  methods: {
    dealwithParam() {
      console.log("abc", this.data);
      //将输入页面传过来的信息进行处理
      this.basicInfo[0].value = this.data["name"];
      this.basicInfo[1].value = this.data["total_count"];
      this.basicInfo[2].value = this.data["activity"];
      this.basicInfo[3].value = this.data["inactivity"];
      this.modelPerformance[0].value = this.data["test_tn"];
      this.modelPerformance[1].value = this.data["test_tp"];
      this.modelPerformance[2].value = this.data["test_fn"];
      this.modelPerformance[3].value = this.data["test_fp"];
      this.modelPerformance[4].value = this.data["test_auc"];
      this.modelPerformance[5].value = this.data["test_acc"];
      this.modelPerformance[6].value = this.data["test_se"];
      this.modelPerformance[7].value = this.data["test_sp"];
      this.hyperParameters[0].value = this.data["hyper_dropout"];
      this.hyperParameters[1].value = this.data["hyper_dropout_gat"];
      this.hyperParameters[2].value = this.data["hyper_fp_2_dim"];
      this.hyperParameters[3].value = this.data["hyper_gat_scale"];
      this.hyperParameters[4].value = this.data["hyper_nheads"];
      this.hyperParameters[5].value = this.data["hyper_nhid"];
      console.log(this.data["radar_fig"]);
      this.imgUrl = "data:image/png;base64," + this.data["radar_fig"];
    },
  },
};
</script>

<style scoped>
.result-detail {
  margin: 20px;
  display: flex;
  width: 100%;
  justify-content: center;
  /* background: cadetblue; */
}

.basic-item,
.performance-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.performance {
  width: 500px;
  height: 400px;
  flex-wrap: wrap;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.performance h3 {
  box-sizing: border-box;
  text-align: center;
  font-weight: lighter;
  width: 100%;
  padding: 10px;
  font-size: 20pt;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
}

.hyper-items {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.hyper-item {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}

.basic-item:nth-child(2n),
.hyper-item:nth-child(2n),
.performance-item:nth-child(2n) {
  background: var(--color2-light);
}

.hyper-parameters {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.basic-infos {
  flex-wrap: wrap;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.basic-infos h3,
.hyper-parameters h3 {
  box-sizing: border-box;
  text-align: center;
  font-weight: lighter;
  width: 100%;
  padding: 10px;
  font-size: 20pt;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background: linear-gradient(to right,
      var(--color2-dark),
      var(--color2-light));
}

.basic-key,
.hyper-key,
.performance-key {
  flex: 1;
}

.basic-value,
.hyper-value,
.performance-value {
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
}

.left {
  /* background: deepskyblue; */
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.right {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
}

.card {
  width: 90%;
  border: 2px solid white;
  margin: 10px;
}

.molecule-img {
  /* background: forestgreen; */
  text-align: center;
  height: 400px;
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.molecule-img img {
  border-radius: 10px;
  width: 600px;
}

h2 {
  text-align: center;
}
</style>
